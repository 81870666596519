<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
    v-if="serverDataLoaded"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Change Status</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <div class="row ma-0">
          <div class="h-75px py-0 col-12">
            <v-select
              v-model="formData.status_id"
              label="Status"
              item-text="text"
              item-value="index"
              :items="serverData.statuses"
              clearable
              outlined
              dense
              :error-messages="status_idErrors"
              @input="$v.formData.status_id.$touch()"
              @blur="$v.formData.status_id.$touch()"
            ></v-select>
          </div>

          <!--end::Body-->
        </div>
        <div class="my-0 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import {
  SET_REPORT_MODAL_STATE,
  SET_REPORT_MODAL_DATA,
} from "@/core/services/store/config.module";

export default {
  mixins: [validationMixin],
  components: {},
  validations: {
    formData: {
      status_id: { required },
    },
  },
  name: "ChangeStatus",
  props: ["refresher", "pageLoader", "ids", "isMultiple"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      status_id: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/fulfillment/asn/update_asn_status")
        .then((response) => {
          this.serverData = response.data;
          this.$v.$touch();
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
    dateFormatter(date) {
      if (!Object.prototype.toString.call(date) === "[object Date]") {
        return date;
      }
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let data = { ...this.formData };
      if (Array.isArray(this.ids)) {
        data.asn_ids = this.ids.map((item) => item.id);
      } else {
        data.asn_ids = [this.ids];
      }
      if (data.date && data.date !== "") {
        data.date = this.dateFormatter(this.formData.date);
      }
      ApiService.post("/fulfillment/asn/update_asn_status_save", data)
        .then((response) => {
          this.$store.commit(SET_REPORT_MODAL_DATA, response.data.messages);
          this.$store.commit(SET_REPORT_MODAL_STATE, true);
          this.toggleModal();
          this.refresher();
          this.pageLoader(false);
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        status_id: null,
      };
    },
  },
  computed: {
    serverDataLoaded: function () {
      return !!this.serverData;
    },
    status_idErrors: function () {
      return this.handleFormValidation("status_id", this);
    },
  },
};
</script>
