<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Release ASN</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="col-12 h-75px">
          <v-datetime-picker
            label="Actual Arrival Time"
            :textFieldProps="{
              outlined: true,
              dense: true,
              'prepend-icon': 'mdi-calendar',
              'error-messages': actual_arrival_timeErrors,
            }"
            :datePickerProps="{}"
            :timePickerProps="{}"
            v-model="formData.actual_arrival_time"
            @input="$v.formData.actual_arrival_time.$touch()"
            @blur="$v.formData.actual_arrival_time.$touch()"
          >
          </v-datetime-picker>
        </div>

        <!--end::Body-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <div class="d-flex flex-wrap justify-content-center align-center">
            <button
              type="submit"
              class="btn btn-danger px-5 py-3 mx-2 order-2 mt-1 mt-sm-0"
              @click="resetCreateForm"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 mx-2 order-1 mt-1 mt-sm-0"
              @click="submitCreateForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import Swal from "sweetalert2";
export default {
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        actual_arrival_time: { required },
      },
    };
  },
  name: "ReleaseModal",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    formData: {
      id: null,
      actual_arrival_time: null,
    },
  }),

  methods: {
    dateFormatter(date) {
      if (!Object.prototype.toString.call(date) === "[object Date]") {
        return date;
      }
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
      } else {
        this.formData.id = this.$store.getters.getItemForAction.id;
      }
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let data = { ...this.formData };
      data.actual_arrival_time = this.dateFormatter(
        this.formData.actual_arrival_time
      );

      ApiService.post("/fulfillment/asn/release", data)
        .then(() => {
          swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    async resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        actual_arrival_time: null,
        id: null,
      };
    },
  },
  computed: {
    actual_arrival_timeErrors() {
      return this.handleFormValidation("actual_arrival_time", this);
    },
  },
};
</script>
